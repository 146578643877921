import { graphql } from 'gatsby';
import { GetSinglePageResult, GraphQLQueryResult } from '../query/pageQuery';
import Page from '../templates/Page';

export default function Template({
  data,
}: GraphQLQueryResult<GetSinglePageResult>) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return <Page metadata={frontmatter!} content={html} />;
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        heading
        heroImage
        transparentHero
        pageClass
      }
    }
  }
`;
